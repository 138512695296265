import './Main-nav-sheet'
import './Booking-sheet'
import './Booking-table-sheet'
import './Example-sheet'
import './Hotellist-sheet'
import './Restuarant-sheet'
import './Lang-sheet'
import './Generic-sheet'

(function () {
    //js for the fixed book button
    $(".fixed-book-icon-close").click(() => {
        $(".fixed-book").hide();
    })
    // Function to call main menu from hotels
    function callMainMenu(e) {
        e.preventDefault()
        const $mainNav = document.querySelector('#side-nav')
        if ($mainNav) {
            $mainNav.getNav().setDelayOverride(true)
            $mainNav.getNav().open()
            $mainNav.getNav().setDelayOverride(false)
        }
    }

})()