import { gsap } from "gsap"
import {
    HOTEL_GET_URL
} from "@/scripts/config/api.config"
import Dropdown from '../dropdown/Dropdown'
import { API } from '@/scripts/common/api/api.services'

const CLASS_PRE = "search-form-group"
const URL = HOTEL_GET_URL

export default class SearchDropdown extends Dropdown {
    constructor(container) {
        super(container)
        this.classPre = CLASS_PRE
        this.$input = this.$el.querySelector(`.${this.classPre}__input`)
        this.$panel = this.$el.querySelector(`.${this.classPre}__autofill`)
        this.$panelContainer = this.$el.querySelector(`.${this.classPre}__autofill-container`)
        this.items = []
        this.autofillLoading = false
        this.searchKey = this.$input.value
        this.prevSearchKey = ''
        this.noItemsFound = false
        this.searchTimer = null
        this.data = this.$el.dataset.url || URL
        this.selectedItem = null
        this.appendToBody = true
        this.APIServices = new API.Controller()
        this.init()
    }

    onInit() {
        // if (this.$input.dataset.selectedId) this.selectValue({
        //     name: this.$input.value,
        //     id: this.$input.dataset.selectedId
        // })

        // Is valid check
        this.isValid()
    }

    resetPanel() {
        gsap.set([this.$panel, this.$panelContainer], { autoAlpha: 1, display: 'block', x: 0, y: 0 })
        gsap.set([this.$panelContainer], { display: 'flex' })
    }

    fetchItems() {
        this.autofillLoading = true

        this.updateHTML()
        if (!this.APIServices.isJson(this.data)) {
            this.APIServices.getFetch(URL)
                .then((data) => {

                    this.autofillLoading = false
                    this.items = data.data
                    this.updateHTML()
                })

            return
        }

        this.autofillLoading = false
        this.items = JSON.parse(this.data)
        this.updateHTML()

    }

    loaderTemplate() {
        const loader = document.createElement('div')
        loader.classList.add('skeleton__loader', 'skeleton__loader--preset-countrylist')
        loader.innerHTML = `<div class="skeleton__mask">
        <div class="skeleton__mask-a"></div>
        <div class="skeleton__mask-b"></div>
        <div class="skeleton__mask-c"></div>
        <div class="skeleton__mask-d"></div>
        <div class="skeleton__mask-e"></div>
        <div class="skeleton__mask-f"></div>
        <div class="skeleton__mask-g"></div>
        <div class="skeleton__mask-h"></div>
        <div class="skeleton__mask-i"></div>
        <div class="skeleton__mask-j"></div>
      </div>`

        return loader
    }

    filterValue(item) {
        if (!this.stringErrors()) {
            const searchVal = this.searchKey
            const searchPatterns = searchVal.split(' ').filter(p => p != '').map(p => p.trim().toLowerCase())
            
            const searchReg = new RegExp(searchPatterns.join('|'), 'g')
            return searchReg.test(item.description.toLowerCase())
        }
        return true
    }

    cleanHtml() {
        this.$panelContainer.querySelector('.autofill') && this.$panelContainer.removeChild(this.$panelContainer.querySelector('.autofill'))
        this.$panelContainer.querySelector('.skeleton__loader') && this.$panelContainer.removeChild(this.$panelContainer.querySelector('.skeleton__loader'))
    }

    updateHTML() {
        this.cleanHtml()
        const $autofillContainer = document.createElement('div')
        $autofillContainer.classList.add('autofill')

        // Loader if loading
        if (this.autofillLoading) {
            $autofillContainer.appendChild(this.loaderTemplate())
            $autofillContainer.appendChild(this.loaderTemplate())
            this.$panelContainer.appendChild($autofillContainer)
            return
        }

        // Items addition
        this.items.forEach((item) => {
            if (this.filterValue(item)) $autofillContainer.appendChild(this.formatItem(item))
        })

        // No items update
        $autofillContainer.querySelectorAll('.autofill__item').length ? this.noItemsFound = false : this.noItemsFound = true
        if (this.noItemsFound) $autofillContainer.innerHTML = '<span>Nothing Found!</span>'
        this.$panelContainer.appendChild($autofillContainer)
    }

    formatItem(item) {
        const type = item.type || null
        const name = item.name || null
        const link = item.link || null
        const dist = item.dist || null

        const $item = document.createElement('div')
        $item.classList.add('autofill__item')

        if (type === 'title') {
            $item.classList.add('autofill__item--title')
            $item.innerHTML = name
        } else {
            if (dist) {
                const $dist = document.createElement('span')
                $dist.classList.add('autofill__item-dist')
                $dist.innerHTML = dist
                $item.appendChild($dist)
            }

            if (name) {
                const $name = document.createElement('span')
                $name.classList.add('autofill__item-name')

                link ? $name.innerHTML = `<a class="autofill__item-link" href=${link}> ${name} </a>` :
                    $name.innerHTML = `${name}`
                $item.appendChild($name)
            }

            $item.addEventListener('click', () => this.selectValue(item))
        }
        return $item
    }

    selectValue(item) {
        this.selectedItem = {
            name: item.name,
            id: item.id
        }
        this.updateInputValue()
    }

    stringErrors() {
        return this.searchKey.match(/^\s*$/) !== null
    }

    updateSearch() {
        this.searchTimer && clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => {
            this.searchKey = this.$input.value.toLowerCase().replace(/\s\s+/g, ' ')
            if ((this.searchKey !== this.prevSearchKey) && !this.stringErrors()) {
                this.updateHTML()
                this.prevSearchKey = this.searchKey
            } else {
                this.updateHTML()
            }
        }, 350)
    }

    updateInputValue() {
        this.$input.value = this.selectedItem.name
        this.$input.setAttribute('data-selected-id', this.selectedItem.id)

        this.$el.dispatchEvent(new CustomEvent('searchSelected', {
            bubbles: true, detail: {
                getValue: () => this.selectedItem
            }
        }))

        if ("createEvent" in document) {
            const evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            this.$input.dispatchEvent(evt)
        }
        else
            this.$input.fireEvent("onchange");

        this.panelClose()
    }

    updateChange() {
       this.isValid()
    }

    isValid() {
        const val = this.$input.dataset.selectedId
        if(this.items.length === 0 && this.$input.dataset.selectedId) {
            this.$input.valid = true
            return
        }
        this.$input.valid = (this.items.findIndex(i => (i.id).toString() === val) === -1) ? false : true
    }

    addListeners() {
        this.$input.addEventListener("focus", () => !this.open && this.panelOpen())
        this.$input.addEventListener("keydown", () => this.updateSearch())
        this.$input.addEventListener("change", () => this.updateChange())

        document.addEventListener('click', (event) => {
            const isClickInside = this.$el.contains(event.target)
            if (!isClickInside) {
                this.open && this.panelClose()
            }
        })
    }
}