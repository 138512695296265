import Navigation from './Navigation'

(function () {
    class HotelListDropdown extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = `generic-menu-${this.$el.getAttribute('id')}`
            this.triggers = document.querySelectorAll(`[href='#${this.$el.getAttribute('id')}'], [data-sheet-target='#${this.$el.getAttribute('id')}']`)
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
            
            this.init()
        }

        createAnim() {
            this.createMainTl()
            !this.isMobile ? this.tl.add(this.openBottomSheet()) : this.tl.add(this.openBottomSheetMobileRight())
        }
    } 

    document.querySelectorAll('.generic-sheet').forEach((s) => new HotelListDropdown(s, 'dropdown'))
})()