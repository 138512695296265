(function() {
    'user strict'

    const materialGroups = document.querySelectorAll('.material-form')
    const stringCheck = /\S/

    function inputValueChecker() {
      const group = this.parentNode
      let value = this.value
      if(stringCheck.test(value)) {
        group.classList.add('material-form--input-populated')
      } else {
        group.classList.remove('material-form--input-populated')
      }
    }

    function addFocus() {
      const group = this.parentNode
      group.classList.add('material-form--focused')
    }

    function removeFocus() {
      const group = this.parentNode
      group.classList.remove('material-form--focused')
    }

    materialGroups.forEach(group => {
      const input = group.querySelector('.material-form__input')
      input.addEventListener('keydown', inputValueChecker)
      input.addEventListener('blur', inputValueChecker)
      input.addEventListener('focus', addFocus)
      input.addEventListener('blur', removeFocus)
    })
})()