import Navigation from './Navigation'

(function () {
    class LangSheet extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = 'lang'
            this.triggers = document.querySelectorAll("[href='#lang'], [data-sheet-target='#lang']")
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
            this.options.triggerActiveText = ''
            this.options.triggerInActiveText = ''
            this.init()
        }

        createAnim() {
            this.createMainTl()
            this.tl.add(this.openBottomSheet())
        }

        resizeFunc() {
            this.dropDownHeightUpdater()
            
            return true
        }
    } 

    document.querySelector('#lang-sheet') && new LangSheet(document.querySelector('#lang-sheet'), 'dropdown')
})()