import {LazyLoad} from './Lazyload'

(function(window) {
    window.addEventListener('load', function(){
        let lazyLoadedElems = document.querySelectorAll('.lazyload')
        setTimeout(function() {
            lazyLoadedElems.forEach((ele) => {
                new LazyLoad(ele)
            })
        }, 250)
    })
})(window)