"user strict"
import variables from "../../scss/exports/exports.scss"
export const settings = {
    locale: window.LOCALE || "en",
    breakpoints: {
        desktop: 1280,
        mobile: parseInt(variables.breakpointMobile)
    },
    
    colors: {
        primary: variables.primary,
        secondary: variables.secondary,
        dark: variables.dark,
        light: variables.light
    },

    carouselWidth: {
        lg: parseFloat(variables.carouselLgWidth),
        xl: parseFloat(variables.carouselXlWidth),
        sm: parseFloat(variables.carouselSmWidth),
    },
    dateDisplayFormat: 'DD/MM/YYYY',
    dateAPIFormat: 'YYYY-MM-DD',
    scrollClassTrigger: 100,
    scrollBarWidth: 0,
    scrollerContainer: window,
    noImageUrl: require('@/assets/images/noimage.jpg').default,
    scrollBarWidthUpdate() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

        document.body.appendChild(outer);

        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";

        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);

        var widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        this.scrollBarWidth = widthNoScroll - widthWithScroll;


    },
    appHeight() {
        const doc = document.documentElement;
        let appHeight = window.innerHeight;
     
        doc.style.setProperty('--app-height', `${appHeight}px`); 
        
    }
}

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    }
}


settings.appHeight();
settings.scrollBarWidthUpdate();
window.addEventListener('resize', debounce(settings.appHeight, 256));

export default settings