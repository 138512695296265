import Navigation from './Navigation'

(function () {
    class HotelListDropdown extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = 'mega-menu'
            this.triggers = document.querySelectorAll("[href='#example-menu'], [data-sheet-target='#example-menu']")
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
            this.delayOverride = true
            this.init()
        }

        createAnim() {
            this.createMainTl()
            !this.isMobile ? this.tl.add(this.openBottomSheet()) : this.tl.add(this.openBottomSheetMobileRight())
        }
    } 

    document.querySelector('#example-menu') && new HotelListDropdown(document.querySelector('#example-menu'), 'dropdown')
})()