import Navigation from './Navigation'

(function () {
    class HotelListDropdown extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = 'hotels-menu'
            this.triggers = document.querySelectorAll("[href='#hotel-list-sheet'], [data-sheet-target='#hotel-list-sheet']")
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
            
            this.init()
        }

        createAnim() {
            this.createMainTl()
            !this.isMobile ? this.tl.add(this.openBottomSheet()) : this.tl.add(this.openBottomSheetMobileRight())
        }
    } 

    document.querySelector('#hotel-list-sheet') && new HotelListDropdown(document.querySelector('#hotel-list-sheet'), 'dropdown')
})()