export const BASE_URL = window.BASE_URL || ""
export const HOTEL_GET_URL = window.HOTEL_GET_URL || "/js/hotellist.json"
export const BOOK_A_STAY_URL = window.BOOK_A_STAY_URL || "https://be.synxis.com/?chain=18067"
export const BOOK_A_STAY_HOTEL_SELECT_URL = window.BOOK_A_STAY_HOTEL_SELECT_URL || "https://my-json-server.typicode.com/n4nirmal09/RoccoDemoApi/stay/synxisHotel"
export const MEALS_URL = window.MEALS_URL || "https://my-json-server.typicode.com/n4nirmal09/RoccoDemoApi/getRestuarant/"
export const MEALS_TIMESLOT_URL = window.MEALS_TIMESLOT_URL || "https://my-json-server.typicode.com/n4nirmal09/RoccoDemoApi/timeSlots/"
export const MEALS_BOOKING_URL = window.MEALS_BOOKING_URL || "https://my-json-server.typicode.com/n4nirmal09/RoccoDemoApi/booking/"
export const MEALS_PERSONAL_DETAILS = window.MEALS_PERSONAL_DETAILS || "https://my-json-server.typicode.com/n4nirmal09/RoccoDemoApi/personalDetails/"
export const BOOK_A_STAY_LOGGING_URL = window.BOOK_A_STAY_LOGGING_URL  || ''
export const PAYMENT_CONTROL_URL = window.PAYMENT_CONTROL_URL || ''
export const TOKEN_URL = window.TOKEN_URL || ''
export const TOKEN_PASS = window.TOKEN_PASS || '9wt5hexh1a2zbasx'