import Navigation from './Navigation'

(function () {
    class DropdownSheet extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = 'booking-menu'
            this.triggers = document.querySelectorAll("[href='#booking-menu'], [data-sheet-target='#booking-menu']")
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
            this.options.triggerActiveText = 'close'
            this.options.triggerInActiveText = 'book'
            this.init()
        }

        createAnim() {
            this.createMainTl()
            this.tl.add(this.openBottomSheet())
        }

        resizeFunc() {
            this.dropDownHeightUpdater()
            
            return true
        }
    } 

    document.querySelector('#booking-sheet') && new DropdownSheet(document.querySelector('#booking-sheet'), 'dropdown')
})()