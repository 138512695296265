import { gsap } from "gsap"
import { utilities, formUtils } from "@/scripts/common/utilities"
import settings from "@/scripts/common/settings"
import Ripple from "@/scripts/ripple"

const CLASS_PRE = "dropdown"
const BACK_DROP_CLASS = "nav-backdrop"

export default class Dropdown {
    constructor(container) {
        this.classPre = CLASS_PRE
        this.$el = container
        this.$trigger = this.$el.querySelector(`.${this.classPre}__trigger`)
        this.$titleText = this.$el.querySelector(`.${this.classPre}__text`) || this.$el.querySelector(`.${this.classPre}__input-text`)
        this.titleInput = this.$titleText?.nodeName === 'INPUT'
        this.$iconOpen = this.$el.querySelector(`.${this.classPre}__icon-svg--open`)
        this.$iconClose = this.$el.querySelector(`.${this.classPre}__icon-svg--close`)
        this.$panel = this.$el.querySelector(`.${this.classPre}__panel`)
        this.$input = this.$el.querySelector(`.${this.classPre}__input`)
        this.$panelContainer = this.$el.querySelector(`.${this.classPre}__panel-container`)
        this.$listItems = this.$el.querySelectorAll(`.${this.classPre}__list-item`)
        this.panelAlign = this.$el.dataset.panelAlign || 'left'
        this.$backDrop = null

        this.selectOptions = !!this.$el.dataset.selectOptions || false
        this.placeHolder = this.$titleText ? this.$titleText.innerHTML : ''
        this.value = this.$el.dataset.value || null
        this.selectedLabel = null

        this.isMobile = window.innerWidth < settings.breakpoints.mobile
        this.mobilePanelPosition = this.$el.dataset.panelMobile || null
        this.mobilePanelFill = !!this.$el.dataset.panelMobileFill || false
        this.mobilePanelTitle = this.$el.dataset.panelMobileHeader || ''
        this.mobilePanelTopCloseAction = !!this.$el.dataset.panelMobileTopCloseAction || false
        this.mobilePanelBottomCloseAction = !!this.$el.dataset.panelMobileBottomCloseAction || false
        this.mobilePanelBottomCloseActionTitle = this.$el.dataset.panelMobileBottomCloseActionTitle || ''

        this.addBackdrop = this.$el.dataset.backdrop || null
        this.open = false
        this.appendToBody = this.$el.dataset.panelAppend === "body"
        this.$appendTarget = this.$el.dataset.panelAppendTarget && document.querySelector(this.$el.dataset.panelAppendTarget) ? document.querySelector(this.$el.dataset.panelAppendTarget) : document.body
        this.panelPosition = this.$el.dataset.panelPosition || 'fixed'
        this.tl = null
    }

    init() {
        
        this.resetPanel()
        if (this.appendToBody) this.appendPanelToBody()
        if (this.mobilePanelTitle || this.mobilePanelTopCloseAction) this.addMobileHeader()
        if (this.mobilePanelBottomCloseAction) this.addMobileFooter()

        this.initProps()
        this.createMainTl()
        this.panelClassToggle()
        if (this.selectOptions) this.selectValueUpdate()
        this.addElementUtilities()
        this.addListeners()
        this.resizeHandler()

        if(typeof this.onInit === 'function') this.onInit()
    }

    addElementUtilities() {
        if (this.selectOptions) this.$el.setValue = (val) => this.setValue(val)
        this.$el.addOption = (option) => this.addOption(option)
        this.$el.removeOptions = (item) => this.removeOptions(item)
        this.$el.panelClose = () => this.panelClose()
    }

    initProps() {
        this.isMobile = window.innerWidth < settings.breakpoints.mobile
    }

    resetPanel() {
        gsap.set([this.$panel, this.$panelContainer, this.$iconOpen, this.$iconClose], { autoAlpha: 1, display: 'block', x: 0, y: 0 })
        //gsap.set([this.$panel], { backfaceVisibility: 'hidden' }) // border invisible bug fix
        gsap.set([this.$panelContainer], { display: 'flex' })
    }

    dropdownToggleClass() {
        this.open ? this.$el.classList.add(this.classPre + "--open") : this.$el.classList.remove(this.classPre + "--open")
    }

    panelClassToggle() {
        if (this.isMobile) {
            if (this.mobilePanelPosition === 'bottom') {
                if (!this.mobilePanelFill) this.$panel.classList.add('elevation-1-up')
                this.$panelContainer.classList.add(`${this.classPre}__panel-container--mobile`)
            }
        } else {
            this.$panel.classList.remove('elevation-1-up')
            this.$panelContainer.classList.remove(`${this.classPre}__panel-container--mobile`)
        }
    }

    addMobileHeader() {
        if (this.$panelContainer.querySelector(`${this.classPre}__panel-header`)) this.$panelContainer.querySelector(`${this.classPre}__panel-header`).remove()
        const $header = document.createElement('div')
        $header.classList.add(`${this.classPre}__panel-header`)
        $header.innerHTML = this.mobilePanelTitle ? `<h5 class="display-5 ${this.classPre}__panel-title">${this.mobilePanelTitle}</h5>` : ''
        this.mobilePanelTopCloseAction && $header.appendChild(this.addMobileHeaderCloseAction())
        this.$panelContainer.prepend($header)
    }

    addMobileHeaderCloseAction() {
        const $close = document.createElement('span')
        $close.classList.add(`${this.classPre}__panel-close`)
        $close.setAttribute('data-ripple', '')
        $close.setAttribute('data-ripple-center', true)
        new Ripple($close)
        $close.innerHTML = `<svg class="icon-svg icon-svg--outline" ><use xlink:href="#icon-times"></use></svg>`
        $close.addEventListener("click", () => this.panelClose())
        return $close
    }

    addMobileFooter() {
        if (this.$panelContainer.querySelector(`${this.classPre}__panel-footer`)) this.$panelContainer.querySelector(`${this.classPre}__panel-footer`).remove()

        const $footer = document.createElement('div')
        $footer.classList.add(`${this.classPre}__panel-footer`)
        $footer.innerHTML = this.mobilePanelBottomCloseActionTitle ? `<h5 class="${this.classPre}__panel-footer-title">${this.mobilePanelBottomCloseActionTitle}<svg class="icon-svg icon-svg--outline" ><use xlink:href="#icon-angle-down"></use></svg></h5>` : ''
        $footer.setAttribute('data-ripple', '')
        $footer.setAttribute('data-ripple-center', true)
        new Ripple($footer)

        $footer.addEventListener("click", () => this.panelClose())
        this.$panelContainer.appendChild($footer)
    }

    // Backdrop function
    createBackdrop() {
        if (!this.addBackdrop) return
        if (this.addBackdrop === 'mobile' && !this.isMobile) return
        this.$backDrop && document.body.removeChild(this.$backDrop)
        this.$backDrop = document.createElement("div")
        this.$backDrop.classList.add(BACK_DROP_CLASS)
        this.$backDrop.classList.add('z-index--high-priority')
        gsap.set(this.$backDrop, {
            left: 0,
            top: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            background: '#000',
            autoAlpha: 0
        })
        document.body.appendChild(this.$backDrop)
        gsap.to(this.$backDrop, {
            duration: 0.4,
            autoAlpha: .5
        })
        this.$backDrop.addEventListener('click', () => this.panelClose())
    }

    removeBackdrop() {
        if (!this.$backDrop) return
        this.$backDrop && gsap.to(this.$backDrop, {
            duration: 0.4,
            autoAlpha: 0,
            onComplete: () => {
                this.$backDrop && document.body.removeChild(this.$backDrop)
                this.$backDrop = null
            }
        })
    }

    appendPanelToBody() {
        this.$appendTarget.appendChild(this.$panel)
        this.$panel.classList.add('z-index--higher-priority')
        this.calcPanelPosition()
    }

    calcPanelPosition() {
        if (!this.appendToBody) return
        const triggerRect = this.$el.getBoundingClientRect()
        const isFill = this.mobilePanelFill
        this.$panel.style.width = `${triggerRect.width}px`
        const panelWidth = triggerRect.width < 120 ? 120 : triggerRect.width
        this.$panel.style.left = this.panelAlign === 'left' ? `${triggerRect.left}px` : `${triggerRect.right - panelWidth}px`
        this.$panel.style.top = this.panelPosition === 'fixed' ?
            `${triggerRect.top + triggerRect.height}px`
            : `${triggerRect.top + triggerRect.height + document.documentElement.scrollTop}px`
        this.$panel.style.position = this.panelPosition

        if (this.isMobile) {
            if (this.mobilePanelPosition === 'bottom') {
                this.$panel.style.top = 'auto'
                this.$panel.style.bottom = 0
                this.$panel.style.width = '100%'
                this.$panel.style.height = !isFill ? '45vh' : `${window.innerHeight - 52}px`// calculating givng header offfset
                this.$panel.style.left = 0
                this.$panel.style.position = 'fixed'
            }
        }
    }

    createMainTl() {
        this.tl && this.tl.kill()
        if (this.isMobile && this.appendToBody) {
            if (this.mobilePanelPosition === 'bottom') {
                this.createMainTlMobileFromBottom()
                return
            }
        }
        this.tl = new gsap.timeline({ paused: true })
            .from(this.$panel, {
                scaleY: 0,
                transformOrigin: "50% 0%",
                display: "none",
                duration: 0.3
            })
            .from(this.$panelContainer, {
                autoAlpha: 0,
                y: -10,
                duration: 0.2
            })
        if (this.$iconOpen)
            this.tl.to(this.$iconOpen, {
                autoAlpha: 0,
                scale: 0,
                duration: 0.2,
                display: 'none',
            })
        if (this.$iconClose)
            this.tl.from(this.$iconClose, {
                autoAlpha: 0,
                scale: 0,
                display: 'none',
                duration: 0.2
            })
    }

    createMainTlMobileFromBottom() {
        this.tl && this.tl.kill()
        this.tl = new gsap.timeline({ paused: true })
            .from(this.$panel, {
                scaleY: 0,
                transformOrigin: "50% 100%",
                display: "none",
                duration: 0.3
            })
            .from(this.$panelContainer, {
                autoAlpha: 0,
                y: 20,
                duration: 0.2
            })
        if (this.$iconOpen)
            this.tl.to(this.$iconOpen, {
                autoAlpha: 0,
                scale: 0,
                duration: 0.2,
                display: 'none',
            })
        if (this.$iconClose)
            this.tl.from(this.$iconClose, {
                autoAlpha: 0,
                scale: 0,
                display: 'none',
                duration: 0.2
            })
    }

    panelOpen() {
        this.open = true
        this.calcPanelPosition()
        this.dropdownToggleClass()
        this.createBackdrop()
        if (typeof this.fetchItems === 'function') this.items.length === 0 && this.fetchItems()
        this.tl.timeScale(1).play()
    }

    panelClose() {
        this.open = false
        this.dropdownToggleClass()
        this.tl.timeScale(2).reverse()
        this.removeBackdrop()
    }

    toggleOpenClose() {
        !this.open ? this.panelOpen() : this.panelClose()
    }

    // Resize listener
    resizeFunc() {
        this.initProps()
        this.appendToBody && this.calcPanelPosition() // Calc Panel Position if appended to body
        this.panelClassToggle()
    }

    resizeHandler() {
        // resize listener
        const resizeDebounceFunc = utilities.debounce(() => this.resizeFunc(), 250)
        window.addEventListener('resize', resizeDebounceFunc)
    }

    // Utility Methods

    addOption(option) {
        const $option = document.createElement('div')
        const $list = this.$panel.querySelector(`.${CLASS_PRE}__list`)
        $option.classList.add(`${CLASS_PRE}__list-item`)

        $option.setAttribute('data-value', option.value)
        $option.setAttribute('data-label', option.label)

        $option.innerHTML = `<span class="${CLASS_PRE}__select-text">${option.name}</span>`
        $option.addEventListener("click", () => this.handleItemClick($option))

        $list.appendChild($option)
    }

    removeOptions(item='all') {
        if(item === 'all') {
            const $options = this.$panel.querySelectorAll(`.${CLASS_PRE}__list-item`)
            $options.forEach($opt => $opt.remove())
        }
            
    }

    // Select options
    updateSelectHtml() {
        if (this.selectedLabel) !this.titleInput ? this.$titleText.innerHTML = this.selectedLabel : this.$titleText.value = this.selectedLabel
    }

    updateOtherTitles(e) {
        const input = e.target
        const $mobileTitle = this.$panelContainer.querySelector(`.${this.classPre}__panel-title`)
        if($mobileTitle) $mobileTitle.innerHTML = `${this.mobilePanelTitle} ${!formUtils.isEmpty(input.value) ? `- <span class="small" style="text-transform:lowercase;letter-spacing:0">${input.value.toLowerCase()}</span>` : '' }`
    }

    updateMenuList() {
        if(!this.selectOptions) return
        const value = this.$titleText.value.trim().toLowerCase()
        const isFromStart = this.$titleText.getAttribute('data-search-matching') === 'fromStart'
        const matchReg = new RegExp(isFromStart ? `^(${value})` : `(${value})`, 'g')
        this.$listItems.forEach((option) => {
            const label = option.dataset.label.toLowerCase()
            if(formUtils.isEmpty(value)) {
                option.classList.remove('d-none')
                return
            }
            label.match(matchReg) ? option.classList.remove('d-none') : option.classList.add('d-none')
        })
    }

    selectValueUpdate() {
        this.$el.value = null
        const $selectedOption = this.$panelContainer.querySelector(`.${this.classPre}__list-item[data-selected]`)
        if ($selectedOption) {
            this.value = $selectedOption.dataset.value
            this.selectedLabel = $selectedOption.dataset.label || $selectedOption.dataset.value
        } else {
            this.value = null
            this.selectedLabel = this.placeHolder
        }

        this.$el.value = this.value
        if(this.$input) this.$input.value = this.value 

        this.$el.dispatchEvent(new CustomEvent('select', {
            bubbles: true, detail: {
                getSelectedLabel: () => this.selectedLabel,
                getValue: () => this.value
            }
        }))

        this.updateSelectHtml()
    }

    setValue(val) {
        if (!this.selectOptions) return

        this.$listItems.forEach($item => {
            $item.removeAttribute('data-selected')
        })

        const $selectedItem = val ? this.$panelContainer.querySelector(`.${this.classPre}__list-item[data-value="${val}"]`) : null

        if ($selectedItem) $selectedItem.setAttribute('data-selected', '')
        this.selectValueUpdate()
    }

    handleItemClick($selectedItem) {
        const selectedVal = $selectedItem.dataset.value
        this.setValue(selectedVal)
        if(!$selectedItem.classList.contains(`${CLASS_PRE}__list-item--checkbox`)) this.panelClose()
    }

    onInputChange() {
        return utilities.debounce((e) => {
            this.updateOtherTitles(e)
            this.updateMenuList()
        }, 500)
    }

    addListeners() {
        if(this.titleInput) this.$trigger.addEventListener('keydown', this.onInputChange())
        this.$trigger.addEventListener("click", () => {
            if(this.$trigger.classList.contains(`${CLASS_PRE}__trigger--readonly`)) return
            this.toggleOpenClose()
        })

        if (this.selectOptions) {
            this.$listItems.forEach($item => {
                $item.addEventListener("click", () => this.handleItemClick($item))
            })
        }


        document.addEventListener('click', (event) => {
            const isClickInside = this.$el.contains(event.target) || this.$panel.contains(event.target)
            if (!isClickInside) this.open && this.panelClose()
        })

        // window.addEventListener('scroll', () => {
        //     this.open && this.panelClose()
        // }) 
    }
}