import {
    BOOK_A_STAY_EP,
    BASE_URL,
    TOKEN_URL,
    TOKEN_PASS
} from "@/scripts/config/api.config"

import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
gsap.registerPlugin(SplitText)

export const API = (($) => {
    class Controller {
        constructor(options) {
            this.options = Object.assign(defaultApiOptions, this.options)
            //this.service = null

            this.animationOptions = {
                ease: 'power2.inOut'
            }
            this.init()
        }

        init() {
            this.setupAjaxBase()
        }

        setupAjaxBase() {
            $.ajaxSetup({
                //mode: 'cors',
                crossDomain: true,
                //referrerPolicy: 'no-referrer',
                // beforeSend: (xhr, options) => {
                //     options.url = this.options.baseUrl + this.options.url
                // }
            })
        }

        get(url) {
            return $.get(url, (data, status) => data)
        }

        async tokenCAll() {
            
            let formData = new URLSearchParams()
            formData.append('password', TOKEN_PASS)
            formData.append('grant_type', 'password')

            const token = await fetch(TOKEN_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Accept": 'application/json'
                },
                body: formData
            })

            return token.json()
        }

        async getFetch(url) {
            let token = ''
            if(TOKEN_URL) token = await this.tokenCAll()
            
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token?.["access_token"] || ''}`
                }
            })
            return res.json()
        }

        async post(url, data) {
            //return $.post(url, data)
            let token = ''
            if(TOKEN_URL) token = await this.tokenCAll()

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token?.["access_token"] || ''}`
                },
                body: JSON.stringify(data).replace(/\\"/g, '')
            }
            const res = await fetch(url, requestOptions)
            return res.json()
        }

        del() {

        }

        isJson(data) {
            try {
                JSON.parse(data)
            } catch (e) {
                return false;
            }
            return true;
        }

        // Utilities functions
        showMessage($parentDiv, { HTTPError = false, successMessage, errorMessage }) {
            const $rows = $parentDiv.querySelectorAll('.row')
            const tl = new gsap.timeline()
            tl.to($rows, {
                autoAlpha: 0,
                ease: this.animationOptions.ease,
                y: -50,
                stagger: 0.1
            })
                .add(this.textAnimation($parentDiv, { HTTPError, successMessage, errorMessage }))
        }

        textAnimation($parentDiv, options) {
            if ($parentDiv.querySelector('.message-alert')) $parentDiv.querySelector('.message-alert').remove()
            const $textDiv = document.createElement('div')
            gsap.set($textDiv, {
                position: 'absolute',
                left: '50%',
                top: '50%',
                xPercent: -50,
                yPercent: -50,
                textAlign: 'center'
            })
            
            $textDiv.classList.add('h1', 'message-alert')
            $textDiv.innerHTML = !options.HTTPError ? `${options.successMessage}` : `<span class="text-error">${options.errorMessage}</span>`
            $parentDiv.appendChild($textDiv)
            const splittedtext = new SplitText($textDiv);
            const tl = gsap.timeline()
            tl.from(splittedtext.chars, {
                duration: 0.4,
                y: 50,
                stagger: 0.05,
                autoAlpha: 0,
                ease: 'back.out(2)',
            })
            return tl
        }
    }

    const defaultApiOptions = {
        baseUrl: BASE_URL
    }

    return {
        Controller
    }
})(jQuery)
