import Navigation from './Navigation'

(function () {
    class RestuarantListDropdown extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = 'resturarants-menu'
            this.triggers = document.querySelectorAll("[href='#restuarant-list-sheet'], [data-sheet-target='#restuarant-list-sheet']")
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
           
            this.init()
        }

        createAnim() {
            this.createMainTl()
            !this.isMobile ? this.tl.add(this.openBottomSheet()) : this.tl.add(this.openBottomSheetMobileRight())
        }
    } 
    document.querySelector('#restuarant-list-sheet') && new RestuarantListDropdown(document.querySelector('#restuarant-list-sheet'), 'dropdown')
})()